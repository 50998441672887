import store from '@/store'
import { computed } from '@vue/composition-api'
import { getRoute } from '../../../utils/utils'

export const useOffers = () => {

  const submitValidatedOffer = (offer) => {
    return new Promise((resolve, reject) => {
      if ('id' in offer) {
        updateOffer(offer)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewOffer(offer)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer/addOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer/updateOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer/removeOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const validateOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer/validateOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const transformProductsToOutgoingInvoice = (offer, selectedProducts) => {
    if (getRoute().name == 'Workflow view') {
      // updateWorkflow(offer)
      let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
      offer.workflow = workflow.id
    }

    return new Promise((resolve, reject) => {
      store.dispatch('offer/transformProductsToOutgoingInvoice', {
        offer: offer,
        selectedProducts: selectedProducts
      })
        .then(response => {
          offer._filters[7].value = false

          if (offer.preTaxAmount == response.data.preTaxAmount) {
            offer._filters[9].value = true
          } else {
            offer._filters[8].value = true
          }

          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadOfferQuote = (offer) => {
    let link = document.createElement('a')
    link.href = offer.quotes.at(-1).url
    link.target = '_blank'
    link.download = offer.quotes.at(-1).name + '.' + offer.quotes.at(-1).extension
    link.click()
  }

  const getOfferStatus = (offer) => {
    if (offer._filters.some(flt => flt.key == '_filter_isCanceled' && flt.value == true)) {
      return 'canceled'
    } else if (offer._filters.some(flt => flt.key == '_filter_isFullyInvoiced' && flt.value == true)) {
      return 'fullyInvoiced'
    } else if (offer._filters.some(flt => flt.key == '_filter_isPartiallyInvoiced' && flt.value == true)) {
      return 'partiallyInvoiced'
    } else if (offer._filters.some(flt => flt.key == '_filter_isDeclined' && flt.value == true)) {
      return 'isDeclined'
    } else if (offer._filters.some(flt => flt.key == '_filter_isWaitingForInvoicing' && flt.value == true)) {
      return 'waitingForInvoicing'
    } else if (offer._filters.some(flt => flt.key == '_filter_isWaitingForSignature' && flt.value == true)) {
      return 'waitingForSignature'
    }
  }

  const getOfferIconStatus = (offer) => {
    if (getOfferStatus(offer) == 'canceled') return { variant: 'danger', icon: 'times' }
    else if (getOfferStatus(offer) == 'fullyInvoiced') return { variant: 'success', icon: 'check' }
    else if (getOfferStatus(offer) == 'partiallyInvoiced') return { variant: 'warning', icon: 'spinner' }
    else if (getOfferStatus(offer) == 'isDeclined') return { variant: 'danger', icon: 'times' }
    else if (getOfferStatus(offer) == 'waitingForInvoicing') return { variant: 'primary', icon: 'signature' }
    else if (getOfferStatus(offer) == 'waitingForSignature') return { variant: 'secondary', icon: 'hourglass' }
  }

  return {
    submitValidatedOffer,
    removeOffer,
    validateOffer,
    transformProductsToOutgoingInvoice,
    downloadOfferQuote,
    getOfferStatus,
    getOfferIconStatus
  }
}