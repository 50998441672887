<template>
  <section class="invoice-preview-wrapper">
    <b-row
        v-if="offer"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
          order="2"
          order-md="1"
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            v-if="$route.name == 'Offer edit'"
            no-body
            class="invoice-preview-card position-relative"
        >
          <validation-observer #default="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(submitOffer)">
              <form-offer
                  :offer="offer"
              />
              <button ref="submitOfferFormRef"
                      class="d-none"></button>
            </b-form>
          </validation-observer>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          order="1"
          order-md="2"
          cols="12"
          md="4"
          xl="3"
          class="offer-actions"
      >
        <buttons-col
            :offer="offer"
            @submitForm="submitForm"
        />

        <documents-col
            :offer="offer"
            @downloadOfferQuote="downloadOfferQuote(offer)"
        />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useOffers } from './useOffers'
import { ValidationObserver } from 'vee-validate'
import { mixinOffers } from './mixinOffers'

import useAPI from '@/utils/useAPI'
import store from '@/store'
import router from '@/router'
import FormOffer from '@/components/form/offer/Offer'
import ButtonsCol from './components/_buttons_col'
import DocumentsCol from './components/_documents_col'
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    FormOffer,
    ButtonsCol,
    DocumentsCol
  },
  mixins: [mixinOffers],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const submitOfferFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const offer = computed(() => {
      return store.getters['offer/getOffer'](router.history.current.params.offerId)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const {
    //   fetchArticles,
    //   fetchUsers,
    //   fetchCompanies,
    //   fetchOffers,
    //   fetchPaymentMethods,
    //   fetchPaymentTerms,
    //   fetchVats
    // } = useAPI()

    const { submitValidatedOffer } = useOffers()

    const submitForm = () => {
      submitOfferFormRef.value.click()
    }

    const submitOffer = () => {
      submitValidatedOffer(offer.value)
          .then(response => {
            router.push({ name: 'Offer view', params: { offerId: offer.value.id } })
          })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    offer.value.billingDate = (new Date()).toISOString()
    let expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 30)
    offer.value.expirationDate = expirationDate.toISOString()

    if (offer.value && offer.value._isValidated == true) {
      router.push({ name: 'Offer view', params: { offerId: offer.value.id } })
    }

    return {
      // Components

      // Data
      submitOfferFormRef,

      // Computed
      offer,

      // Methods
      submitForm,
      submitOffer
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>